var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-center align-center",
      staticStyle: { height: "80vh" },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-img", {
                  staticClass: "mx-auto",
                  attrs: {
                    width: "80%",
                    height: "100%",
                    src: require("@/assets/noDataAvailable.jpg"),
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "font-weight-bold text-h4 d-flex justify-center ",
                  },
                  [_vm._v(_vm._s(_vm.$t("message.common.noResults")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }